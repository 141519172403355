'use client';

import { useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    console.error(error);
    Sentry.captureException(error);
  }, [error]);

  return (
    <div className={`flex flex-col items-center justify-center gap-4 p-8`}>
      <div className={`font-neco text-3xl font-bold sm:text-5xl`}>
        Oops! Something went wrong on our end
      </div>
      <div>
        Please refresh your browser or try again later. If the problem persists,
        feel free to contact us at{' '}
        <span className={`font-bold`}>support@vocalini.com</span>.
      </div>
    </div>
  );
}
